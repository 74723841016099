import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";

export default class ScanIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <body className="page--scanningData" />
        </Helmet>
        <div className="articleWrapper articleType--themesList">
          <header className="pageHeader pageHeader--noImg grid--narrow">
            <h1>Scanning Library</h1>
            <a href="https://docs.google.com/presentation/d/15FLP2JQr9pGT5rBtn84Ics8LdLZhMsMOeoJFAwSsRF0/edit?usp=sharing" target="_blank" rel="noreferrer" className="button">View the Scanning deck</a>
          </header>
        </div>
      </Layout>
    );
  }
}
